html {
	scroll-behavior: smooth;
}

.my-navigation {
	display: flex;
	padding-top: 30px;
	padding-bottom: 30px;
	flex-direction: row;
	justify-content: end;
	align-items: end;
	gap: 20px;

	> img {
		width: 350px;
	}

	> div {
		display: flex;
		flex-grow: 3;
		flex-direction: column;
		justify-content: end;
		gap: 10px;

		> div {
			display: flex;
			flex-wrap: wrap;
			justify-content: end;
			flex-direction: row;
			gap: 20px;

			> div {
				justify-content: end;
			}
		}
	}
}

@media (max-width: 800px) {
	.my-navigation {
		flex-direction: column;
		align-items: center;
		> div {
			justify-content: center;
			> div {
				gap: 10px;
				justify-content: center;
			}
		}
	}
}
