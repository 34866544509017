$primary: #ac9957;

// Border-Radius
$border-radius: 0rem;
$border-radius-sm: 0rem;
$border-radius-lg: 0rem;
$border-radius-pill: 0rem;



